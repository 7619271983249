










































































































































































import Vue from "vue";
import _ from "lodash";
import store from "../../store/session";
import * as orderHistory from "../../model/orderHistory";
import { Shop } from "../../model/entity/shop";
import { OrderStatus } from "../../model/entity/orderSetting";
import { ServedCode } from "../../model/entity/orderSetting";

export default Vue.component("OrderHisotry", {
  components: {
    QSkeleton: () => import("../../components/elements/QSkeleton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    SOSimplePrice: () =>
      import("../../components/components/SOSimplePrice.vue"),
    QCartListTotal: () => import("../../components/elements/QCartListTotal.vue"),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    shop() {
      return store.state.shop;
    },
    isNaizei(): boolean {
      const shop = store.state.shop as Shop;

      return shop.is_naizei || false;
    },
    histories() {
      const histories = store.state.histories;
      return histories;
    },
    paymentHistories() {
      const paymentHistories = store.state.paymentHistories;
      return paymentHistories;
    },
    summary() {
      const summary = store.state.summary;
      return summary;
    },
  },
  mounted() {
    this.isLoading = true;
    store
      .dispatch("fetchOrderHistory")
      .catch((err) => {
        console.error(err.message);
        this.$router.push("/error?message=注文履歴の取得に失敗しました。");
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
  },
  filters: {
    statusIcon(order) {
      if (order.status === OrderStatus.STATUS_CANCELED) {
        return "cancel";
      }
      if (order.status === OrderStatus.STATUS_PAID) {
        return "paid";
      }
      if (order.is_served === ServedCode.SERVED_DONE) {
        return "serving";
      }
      return "";
    },
  },
});
